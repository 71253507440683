import React from 'react';
import './MainLandingPage.css';
import LandingNav from '../Navigation/LandingNav';
import Footer from '../Navigation/Footer';
import { NavLink } from 'react-router-dom';
    
function MainLandingPage(){
   
    

    return (
        <div>
      <LandingNav/>

    {/* Header Section */}
    <div className="header-section">
      <div className="header">
        <div className="header-text">
          <div className="intro-phrase">The Canadian Armed Forces Around the World Since 1949</div>
          <div className="explore-text">Explore Canada’s military role as part of United Nations, the North Atlantic Treaty Organization (NATO), and North American Air Space Defence Command collective security policy.</div>
          <NavLink to={"/missions"}>
          <button className="view-missions">View Missions</button>
          </NavLink>
        </div>
        <div className="header-image">
          <img
          className='h-img'
            loading="lazy"
            src="/main-header-image.png"
            alt="War History"
          />
        </div>
      </div>
      
    </div>



    {/* About Section */}

    <div className="about-container">
      <header className="about-header">
        <h1 className="about-section-title">
          Section leading to About us{" "}
          <span className="about-highlight">page</span>
        </h1>
      </header>
      <div className="about-content">
        <div className="about-column">
          <p className="about-section-description">
            In service of Canada is ....... Et ullum iisque conclusionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda interesset no, probo eloquentiam mei eu. Sea id malorum dolor.Et ullum iisque conclusionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda interesset no, probo eloquentiam mei eu. Sea id malorum dolor.Et ullum iisque conclusionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda interesset no, probo eloquentiam mei eu. Sea id malorum dolor.Et ullum iisque conclusionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan , 
            
            </p>
          <NavLink to={"/about-us"}>
          <button className="about-learn-more">Learn More</button>
          </NavLink>
        </div>
        <div className="">
          <img
          className='about-image-wrapper'
            loading="lazy"
           src='/about_section_image.png'
            alt="Image"
          />
        </div>
      </div>
    
    </div>
  

  {/* Featured Mission Section  */}

    <div className="featured-missions-container">
      <img
        loading="lazy"
        src='/featured-mission-image.jpg'
        className='featured-missions-img'
        />
        
      <div className="mission-container">
      <p className='mission-title'> Explore Timelines</p>
       
        <div className="mission-details">
          
          <div className="featured-card-column">
            <div className="mission-info">
              <div className="mission-name">
                UNEF I <br/>  Suez Crisis <br/> 2nd Arab Israeli War
              </div>
              <div className="f-mission-text">
                The Suez Crisis was a conflict that arose in 1956 over control
                of the Suez Canal, which is a vital waterway in northeastern
                Egypt that connects the Mediterranean Sea to the Red Sea. 
              </div>

              <NavLink to={"/timeline"} state={{mission_id:1, language:'en'}}>
                <button className="about-learn-more">View Timeline</button>
              </NavLink>
              
            </div>
          </div>
          <div className="featured-card-column">
            <div className="mission-info">
              <div className="mission-name">
                BOSNIAN WAR <br/> UNPROFOR <br/> 
              </div>
              <div className="f-mission-text">
                The Bosnian War, occurring from 1992 to 1995, was a complex conflict marked 
                by ethnic tensions and violence between Bosniaks, Serbs, and Croats, resulting 
                in widespread atrocities and genocide, particularly in Srebrenica. 
                International intervention was deemed necessary to stop the bloodshed and 
                facilitate peace negotiations due to the failure of local authorities to protect 
                civilians and the escalation of ethnic cleansing campaigns.
              </div>

              <NavLink to={"/timeline"} state={{mission_id:8, language:'en'}}>
                <button className="about-learn-more">View Timeline</button>
              </NavLink>
              
            </div>
          </div>
          <div className="featured-card-column">
            <div className="mission-info">
              <div className="mission-name">
                AFGHANISTAN WAR <br/>  The Canadian Armed Forces in <br/> Afghanistan
              </div>
              <div className="f-mission-text">
              The trigger that brought the United States, Canada, and allied nations to Afghanistan came on September 11, 2001 (9/11) 
              when Al-Qaeda terrorists attacked the United States.
              </div>

              <NavLink to={"/timeline"} state={{mission_id:7, language:'en'}}>
                <button className="about-learn-more">View Timeline</button>
              </NavLink>
              
            </div>
          </div>
        </div>
      </div>
    </div>



 {/* Teacher Resources Section  */}

 <div className="teachers-resources-container">
  <header className="teachers-resources-header">
    Section leading to Teachers Resources Page
  </header>
  <div className="teachers-resources-description">
    Text on what the teachers resources includes. Suspendisse varius enim in eros elementum tristique. Duis cursus, midipisci.
  </div>

  <NavLink to={"/teacher-resources"}>
          <button className="teachers-resources-button">  View Teachers Resources </button>
          </NavLink>

 
</div>


 {/* Veteran Interviews Section  */}
 <div className="veteran-interviews-section">
  <img
    loading="lazy"
    src="/vet_image.jpg"
    className="vet-inter-image"
    alt="Veteran Interviews"
  />
  <div className="vet-inter-content">
    <header className="vet-heading">
      Section Leading to Veteran Interviews
    </header>
    <p className="vet-description">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius
      enim in eros elementum tristique. Duis cursus, mi quis viverra ornare,
      eros dolo. Lorem ipsum dolor sit amet, consectetur adipisci
    </p>

    <NavLink to={"/veteran-interviews"}>
          <button className="about-learn-more">Veteran Interviews</button>
          </NavLink>
              
  </div>
</div>

<Footer/>

    </div>

    )
    
  }

  export default MainLandingPage;


      