import React from 'react';
import './TeacherResources.css';
import LandingNav from '../Navigation/LandingNav';
import Footer from '../Navigation/Footer';
import { NavLink } from 'react-router-dom';
import Accordion from '../Accordion/Accordion';
    
function TeacherResources(){

    return(

        <div>

            <LandingNav/>

            {/* Teacher Resources Header Section */}
            
            <div className='teacher-header'>
                    <img
                    loading="lazy"
                    src='/teacher-header-image.jpg'
                    className='teacher-header-image'
                    />
            <div className='teacher-header-content'>

            <h2 className='teacher-header-heading'> 
            Teacher Resources
            </h2>

            <p className='teacher-header-text'>

            Brief overview of project...    
            </p>

            </div>

            </div>




            {/* Teacher Project Overview Section */}

            <div className='teachers-faq-section'>
<h2 className='about-faq-heading'>
 Project Overview
</h2>

<Accordion allowMultipleOpen>
        <div label='MISSION LANDING PAGE' isOpen>
          <p>
            In the Mission Landing Page you can select a specific mission and start the journey of learning about the conflict and Canada's involvment.
            <br/> 
            New missions will be added once they are completed. 
          </p>
          
          <img
            className="acc-img"
            src='acc-img1.jpg'
            alt=""
            />

            <p>Students can choose to jump directly to the 'Misson Control' where they can learn about how and why the conflict began, as well as several videos that are used to introduce students to the main themes.
            <br/>
            Students can also link to the 'Timeline' where all of the events leading up to, including and after the mission help guide students to grasp a better understanding of the conflict.
            <br/>
            The map is used to help students identify the region where the conflict took place.
            </p>
         
        </div>


        <div label='MISSION CONTROL'>
            <p>
              The Mission Control is designed to give a summary of the mission. Although it is impossible to condense the complexities of a major conflict into a single webpage, 
              this is a great place to start to build a foundation of themes and key concepts, while being introduced to the functionality between text and map animations.
          </p>
          <br/>
          <p>
            There are four main sections to the Mission Control: <br/>
            <ul>
              <li>
                <strong>Video Section</strong> - several videos have been included that can be used to identify the major players involved in the conflict, 
                different perspectives and how tension increased over time. Although it is encouraged to flip the classroom 
                (i.e., have students watch the video for homework so they come to class prepared to discuss), you can certainly watch one or more videos 
                as a class to identify key themes to spark a discussion. There is also a set of inquiry questions that can be used in the ‘Activities’ section 
                to help guide students through historical thinking concepts.
              </li>
              <li>
                <strong>Mission Backstory</strong> - the Big-Six Historical Thinking Concepts introduce the main themes that can be integrated into the rest 
                of course curriculum. A synopsis of key concepts assists you in understanding how Continuity and Change, Cause and Consequence, Historical Perspective, 
                Evidence, Historical Significance and the Ethical Dimension can be embedded and used to describe different facets of a mission.
              </li>
              <li>
                <strong>Cause of the Conflict</strong> - things in real life are rarely black and white, and when discussing Canada’s involvement in different missions, 
                it is important to look at varying perspectives to understand how different actions and events evolved into conflict. 
              </li>
              <li>
                <strong>Setting</strong> - the setting oftentimes helps to view how neighbouring communities, nations, and regions create arbitrary boundaries 
                that lead to ethnic, racial and religious tensions. Colonial roots often play a pivotal role in developing deep-seated animosity between the ruling 
                class and the regular population. Over time, these can result in major conflicts.
              </li>
            </ul> 
          </p>
        </div>

        <div label='TIMELINE'>
            <p>
              The Timeline is used to give a chronological sequence of events that established the need for Canada to be involved in domestic 
              and international disputes. Each mission has a plethora of timeline events that can be used to gain a greater understanding of a conflict. <br/>
              The Timeline can be filtered from introductory events to a much more advanced version depending on the amount of time you wish to spend learning about a mission.<br/>
              Each event is described using a title, subtitle, and a more in-depth description. Also, an image is used to help visualize each specific event 
              that took place. The map animates so you are able to gain a greater context of the geographical location of where an event took place.        
          </p>
          <br/>
          
        </div>

        <div label='ACTIVITIES'>
            <p>
            The Activities are intended to develop formative learning skills and allow teachers and students to customize their investigation based on unique learning styles.<br/>
            Recommendations are given depending on the amount of time you wish to spend on this unit. One-, Three-, and Five-Day Options are given 
            with specific activities to complete over the allotted amount of time. However, these options are only recommendations and you can mix and match 
            the activities for an entire class, or based on an individual’s learning strengths and weaknesses. <br/>
            Each activity is designed to incorporate the Big-Six Historical Thinking Concepts to help develop 21st-century learning skills. <br/>
            Once you are inside the Mission Control, or Timeline, a button in the top of the webpage will give you access to the Activities section.
          </p>
          <br/>
          <p>
            Here is a list of the Activities provided:<br/>
            <ul>
              <li>Introduction Video</li>
              <li>Mission Control</li>
              <li>Timeline</li>
              <li>Canada's Role</li>
              <li>Inquiry Questions</li>
              <li>Veteran Testimonials</li>
              <li>Veteran Interview</li>
              <li>Deconstructing a Photo</li>
              <li>Alliances</li>
              <li>Major Players</li>
              <li>Commemoration Evaluation</li>
            </ul>
          </p>
        </div>

       
      </Accordion>

</div>

             {/* Teacher FAQ Section */}

             <div className='about-faq-section'>
<h2 className='about-faq-heading'>
 Teachers FAQS
</h2>

<Accordion allowMultipleOpen>
        


        <div label='Which mission(s) should we study?'>
            <p>
              As a teacher, you can decide to give each student a choice of what mission they would like to research, which helps to give ownership of their own learning pathway. <br/>
              Students can then compare the different missions that Canada has served in to better understand the similarities and differences of service.
            </p>
            <br/>
            <p>
              However, it may also be beneficial to look at a specific mission as an entire class and see what themes the students can identify.<br/>
              More missions will be added over the next several years starting with: UNEF I, and the War in Afghanistan.
            </p>
        </div>

        <div label='How long should my class spend using the In Service of Canada platform?'>
          <p>
            The In Service of Canada platform has been designed to allow teachers and students to spend different amounts of time depending on how much class time can be dedicated to learning about Canada’s involvement in different conflicts and missions post-1949.
          </p>
          <br/>
          <p>
            In the Student Activities section there are recommended activities that students can use to help build a greater depth of knowledge.<br/>
            Each conflict will have a variety of student tasks that will focus on distinct learning objectives in a one-day, three-day or five-day approach.<br/>
            A one-day module will allow students to get a brief snapshot of a mission, while a five-day module will allow students to examine the intricate details that caused the conflict and why it may have been necessary for Canada to get involved.
          </p>
          <br/>
        
        </div>

        <div label='How do I know which activities students should complete?'>
            <p>
            When a mission has been selected, a user can navigate to the ‘Student Activities’ section of the platform. 
            Once there, there are several different sets of activities that have been curated  depending on the amount of time a person has. 
          
          </p>
          <br/>
          <p>
            For instance, in the One-Day Option it is recommended to begin the investigation by watching one of the videos provided. 
            This can also be done in a ‘flipped classroom’ setting, where students watch the video at home, 
            and come prepared to the classroom to discuss the major concepts when this unit begins. <br/>
            The second activity introduces students to the map in the Mission Control that enables them to see how geography impacted the conflict, 
            while also learning about the ‘Backstory’, ‘Cause of Conflict’, and the ‘Setting’. <br/>
            The final proposed task in the One-Day Option is to view an Introduction Timeline to see how specific dates and events led to a global response.

          </p>
          <p>
            All of the One-, Three-, and Five-Day Options are only suggestions and can be customized based on an individual’s own experiences, or class parameters. 
          </p>
        </div>

        <div label='What guiding principles are used to determine participation in a United Nations peace and security operation?'>
            <p>
            The interconnectedness of nations around the world makes nation participation in peace
            and security operations challenging and complex. However, there are three basic
            principles used as a starting point for participation consideration. <br/>
            They are:
          </p>
          <br/>
          <p>
            <ol>
              <li>Consent of the parties directly involved in the conflict</li>
              <li>Impartiality of potential nation (s) offering support to main parties</li>
              <li>Non-use of force except in self-defence or in support of the accepted mandate.</li>
            </ol>
          </p>
          <p>
            For a detailed understanding of each principle please refer to the United Nations
            <a href="https://peacekeeping.un.org/en/principles-of-peacekeeping" target="_blank">Principles of Peacekeeping.</a> 
          </p>
        </div>

        {/* <div label='Question 5'>
            <p>
          Usionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda interesset no, probo 
          eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi.\
           Fugit expetenda interesset no, probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan 
           noluisse, nostrum accumsan movet salutandi. Fugit expetenda 
          
          </p>
          <br/>
          <p>Usionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda interesset no, 
            probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet 
            salutandi. Fugit expetenda interesset no, probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum 
            accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda usionemque eam, mel ad erat cum </p>
        </div>  */}
      </Accordion>

</div>

            
            
            
            
            
            
            
            
            
            
            <Footer/>


        </div>




    )






}

export default TeacherResources;
  